// Functions for exposing the values to the user in spanish

// Global constants
import * as con from "../GlobalConstants"
import { round } from "../GlobalFunctions"


/**
 * Translation dictionaries for the different parameters and variables of
 * the application
 */
const USER_BANDS = {[con.LOWER_BAND] : 'Banda Baja',
                    [con.MID_LOW_BAND] : "Banda Media Baja",
                    [con.MID_BAND] : "Banda Media",
                    [con.MID_HIGH_BAND] : "Banda Media Alta",
                    [con.HIGHER_BAND] : 'Banda Alta'}

const USER_BANDS_SHORT = {[con.LOWER_BAND] : 'B. Baja',
                            [con.MID_LOW_BAND] : "B. M. Baja",
                            [con.MID_BAND] : "B. Media",
                            [con.MID_HIGH_BAND] : "B. M. Alta",
                            [con.HIGHER_BAND] : 'B. Alta'}

                    

const TRANSACTIONS_IMPORTER = {[con.EXPOSURES] : "Exposiciones",
                      [con.COVERAGES_FWD] : "Coberturas FWD",
                      [con.COVERAGES_SPOT] : "Coberturas SPOT",
                      [con.COVERAGES_OPTION] : "Coberturas Opciones",
                      [con.ACCOUNTS] : "Cuentas"}

const TRANSACTIONS_EXPORTER = {...TRANSACTIONS_IMPORTER,
                                [con.COVERAGES_SPOT] : "Monetizaciones"}                      

const USER_PARAMETERS = {[con.VAR] : "VaR",
                         [con.PERIODICITY_MONTH] : "Mensual",
                         [con.PERIODICITY_WEEK] : "Semanal",
                         [con.PERIODICITY_DAY] : "Diaria",
                         [con.EXPORTER] : "Exportador",
                         [con.IMPORTER] : "Importador",
                         [con.ACTIVE] : "Activa",
                         [con.EXPIRED] : "Vencida",
                         [con.PREPAYED] : "Prepagada",
                         [con.CASH_REGISTER] : "Caja",
                         [con.NATURAL_COVERAGE] : "Cobertura Natural",
                         [con.COMPENSATION_ACCOUNT] : "Cuenta de Compensación",
                         [con.BUY] : 'Compra',
                         [con.SELL] : 'Venta',
                         [con.MARKET_10_DAYS] : "2 Semanas",
                         [con.MARKET_30_DAYS] : "1 Mes",
                         [con.MARKET_90_DAYS] : "3 Meses",
                         [con.MARKET_180_DAYS] : "6 Meses",
                         [con.MARKET_360_DAYS] : "1 Año",
                         [con.MARKET_TOTAL_DAYS] : "Histórica",
                         [con.PUT]: "Put",
                         [con.CALL]: "Call",}


const TRANSACTION_COLUMN_NAMES = {
    [con.NEW_FORWARD_RATE] : "TASA VALORACIÓN",
    [con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE] : "PyG PESOS X DÓLAR"
}

const COUNTERPARTIES_NAMES = {
    [con.BANCOLOMBIA] : "Bancolombia",
    [con.DAVIVIENDA] : "Davivienda",
    [con.BOGOTA] : "B. Bogota",
    [con.SCOTIABANK] : "Scotiabank",
    [con.ITAU] : "Itaú",
    [con.CORFICOLOMBIANA] : "Corficolombiana",
    [con.OCCIDENTE] : "B. Occidente",
    [con.BBVA] : "BBVA",
    [con.SANTANDER] : "Santander",
    [con.SUDAMERIS] : "GNB Sudameris"
}

const CALCULATOR_PARAMETER_NAMES = {

        [con.CALCULATOR_INSTRUMENT_TYPE] : "Tipo d Instrumento",
        [con.CALCULATOR_EXECUTION_DATE] : "Fecha de Ejecución",
        [con.CALCULATOR_EXPIRY_DAYS] : "Dias para Vencimiento",
        [con.CALCULATOR_EXPIRATION_DATE] : "Fecha de Vencimiento",
        [con.CALCULATOR_NOMINAL] : "Nominal",
        [con.CALCULATOR_BANK_SPOT] : "Spot del Banco",
        [con.CALCULATOR_QUOTED_DEVALUATION] : "Devaluación Cotizada",
        [con.CALCULATOR_QUOTED_FORWARD_RATE] : "Tasa Forward Cotizada",
        [con.CALCULATOR_LOCAL_SPOT] : "Spot",
        [con.CALCULATOR_REFERENCE_DEVALUATION] : "Devluación Referencia",
        [con.CALCULATOR_REFERENCE_FORWARD_RATE] : "Tasa Forward Referencia",
        [con.CALCULATOR_LOCAL_FORWARD_CURVE] : "local_forward_curCurva Forward",
        [con.CALCULATOR_LOCAL_SPREAD] : "Spread",
        [con.CALCULATOR_FWD_POINTS] : "Puntos Forward",
        [con.CALCULATOR_FWD_POINT_DIFF] : "Diferencia en Puntos Forward",
        [con.CALCULATOR_COP_DEVALUATION_DIFF] : "Diferencia en Pesos por Devaluación",
        [con.CALCULATOR_TOTAL_COP_DIFF] : "Diferencia en Pesos Totales",
        [con.CALCULATOR_P_AND_G] : "P y G",
        [con.CALCULATOR_SPOT_DIFF] : "Diferencia en Spot",
        [con.CALCULATOR_EDITING_DAYS] : "Editando Dias",
        [con.CALCULATOR_EDITING_QUOTED_FORWARD] : "Editando Tasa Forward Cotizada",
}

const ORION_SIGNALS = {
    [con.ORION_SIGNAL_STRONG_SELL] : "Venta Fuerte", 
    [con.ORION_SIGNAL_SELL] : "Venta",
    [con.NEUTRAL] : "Neutral",
    [con.ORION_SIGNAL_BUY] : "Compra",
    [con.ORION_SIGNAL_STRONG_BUY] : "Compra Fuerte",
}

/**
 * Translate a given Band ID
 * @param {string} band - Band Id
 * @returns {string} - Human readable equivalent
 */
export const translateBands = (band)  =>
{
    if(band in USER_BANDS)
        return(USER_BANDS[band])

    return(band)
}

/**
 * Translate a given Band ID into its short version
 * @param {string} band - Band Id
 * @returns {string} - Short human readable equivalent
 */
 export const translateBandsShort = (band)  =>
 {
     if(band in USER_BANDS_SHORT)
         return(USER_BANDS_SHORT[band])
 
     return(band)
 }
 

/**
 * Translate a given Parameter ID
 * @param {string} param - Parameter Id
 * @returns {string} - Human readable equivalent
 */
export const translateParameter = (param) => {

    if(param in USER_PARAMETERS)
        return(USER_PARAMETERS[param])
    
    return(param)
}

/**
 * Translate a given Band ID
 * @param {string} band - Band Id
 * @returns {string} - Human readable equivalent
 */
export const counterpartiesNames = (name)  =>
{
    if(name in COUNTERPARTIES_NAMES)
        return(COUNTERPARTIES_NAMES[name])

    return(name)
}

/**
 * Translate a given type of Transaction (Exposures, Coverages or Accounts)
 * @param {string} trans - Transaction Id
 * @param {string} [clientType=con.IMPORTER] - Client type
 * @returns {string} - Human readable equivalent
 */
export const translateTransactions = (trans, clientType = con.IMPORTER) =>
{
    let dic = TRANSACTIONS_IMPORTER
    if(clientType === con.EXPORTER)
        dic = TRANSACTIONS_EXPORTER
    
    if(trans in dic)
        return(dic[trans])

    return(trans)
}


/**
 * Format the given phone number into a human readable format 
 * @param {string} phoneNumber - Phone number in its WA international format
 * @returns Human readable phone number
 */
export const formatPhoneNumber = (phoneNumber)  =>
{    
    if(phoneNumber.length !== 12)
        return(phoneNumber)
        
    let country_code = phoneNumber.slice(0,2)
    
    phoneNumber = phoneNumber.slice(2)
    while(country_code.length > 1 && country_code[0] === '0')
        country_code = country_code.slice(1)

    let resp = `+${country_code} ${phoneNumber.slice(0,3)} ${phoneNumber.slice(3)}`
    return(resp)
}


/**
 * Translate a given Orion Signal ID
 * @param {string} signal - Orion Signal Id
 * @returns {string} - Human readable equivalent
 */
export const translateOrionSignal = (signal)  =>
{
    if(signal in ORION_SIGNALS)
        return(ORION_SIGNALS[signal])

    return(signal)

}

/**
 * Translate a given DAYS BEHIND VALUE
 * @param {number} days - Days ago to format 
 * @returns {string} - Human readable equivalent
 */
 export const translateOrionDaysAgoValue = (days)  =>
 {
     switch (days) {
        case 1:
            return("Actual")            
        
        case 10:
            return("Hace 15 días")

        case 20:
            return("Hace 1 mes")

        default:
            return(`Hace ${round(days*365/con.DAYS_IN_YEAR)} días`)
     }

 
 }



/**
 * Translate and rename specific transaction columns
 * @param {object} column - Column with all its information 
 * @returns {object} - New column including the new name
 */
 export const translateTransactionColumnName = (column)  =>
 {
     if(column[con.ID] in TRANSACTION_COLUMN_NAMES)
        column = {...column, [con.NAME] : TRANSACTION_COLUMN_NAMES[column[con.ID]]}
    
     return(column)
 
 }
 
 
 /**
 * Translate a given Calculator Parameter
 * @param {string} parameter - Parameter Id
 * @returns {string} - Human readable equivalent
 */
export const translateCalculatorParameter = (parameter)  =>
{
    if(parameter in CALCULATOR_PARAMETER_NAMES)
        return(CALCULATOR_PARAMETER_NAMES[parameter])

    return(parameter)
}